import { Switch } from '@/components/ui/switch'
import { cls } from '@/utils'
import { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import IconChevronRight from '@haiper/icons-svg/icons/outline/chevron-right.svg'
import GSUpload from '@/components/gs-upload'
import { GenerationSetting, InnerUploadFile, ModelVersion, ThreeStage } from '@/types'
import IconFirstFrame from '@/public/assets/first-frame.svg'
import IconMiddleFrame from '@/public/assets/middle-frame.svg'
import IconLastFrame from '@/public/assets/last-frame.svg'
import { useAtomValue } from 'jotai'
import { creationInputAtom } from '@/atoms'
import Button from '@/components/button'
import AfcTimeline, { AfcTimelineProps } from './timeline'
import { cloneDeep } from 'lodash-es'
import { useBreakpoint } from '@/hooks/useBreakPoint'

// export const showAfcModel2Entry = !isProduction
export const showAfcModel2Entry = true

export interface KeyframeConditioningProps {
  className?: string
  files: ThreeStage<InnerUploadFile | null>
  setFiles: Dispatch<SetStateAction<ThreeStage<InnerUploadFile | null>>>
  uploading: ThreeStage<boolean>
  setUploading: Dispatch<SetStateAction<ThreeStage<boolean>>>
  enableAfc?: boolean
  setEnableAfc?: Dispatch<SetStateAction<boolean>>
  beforeUpload?: (file: File) => Promise<boolean>
  settings: GenerationSetting
  extra?: ReactElement
  setFrameIndicies: Dispatch<SetStateAction<ThreeStage<number | null>>>
}

export default function KeyframeConditioning({
  files,
  setFiles,
  setFrameIndicies,
  uploading,
  setUploading,
  enableAfc,
  setEnableAfc,
  extra,
  settings,
  beforeUpload,
  className,
}: KeyframeConditioningProps) {
  const creationInput = useAtomValue(creationInputAtom)
  const isModel2 = creationInput?.version === ModelVersion.TWO
  const enableTimeline = enableAfc && isModel2
  const { isBelowMd } = useBreakpoint('md')

  const handleAfcChange = useCallback(
    (val: boolean) => {
      if (!val) {
        setFiles?.((old) => ({ ...old, second: null, third: null }))
      }
      setEnableAfc?.(val)
    },
    [setEnableAfc, setFiles],
  )

  useEffect(() => {
    if (isModel2 && !showAfcModel2Entry) {
      handleAfcChange(false)
    }
  }, [isModel2, handleAfcChange])

  const openModelVersionPicker = useCallback(() => {
    const el = document.getElementById('creation-model-version-button')
    el?.click()
  }, [])

  const [timelineItems, setTimelineItems] = useState<AfcTimelineProps['items']>(() => {
    const items: AfcTimelineProps['items'] = [
      {
        className: 'bg-surface-active border-border-active',
        thumbnail: files.first?.thumbnailUrl,
        visible: !!files.first?.thumbnailUrl,
      },
      {
        className: 'bg-surface-caution border-border-caution',
        thumbnail: files.second?.thumbnailUrl,
        visible: !!files.second?.thumbnailUrl,
      },
      {
        className: 'bg-surface-success border-border-success',
        thumbnail: files.third?.thumbnailUrl,
        visible: !!files.third?.thumbnailUrl,
      },
    ]

    return items
  })

  useEffect(() => {
    const total = (settings.duration ?? 0) * 8
    const newFrameIndicies = {
      first: timelineItems[0]?.visible ? Math.round(((timelineItems[0].percent ?? 0) * total) / 100) : null,
      second: timelineItems[1]?.visible ? Math.round(((timelineItems[1].percent ?? 0) * total) / 100) : null,
      third: timelineItems[2]?.visible ? Math.round(((timelineItems[2].percent ?? 0) * total) / 100) : null,
    }
    setFrameIndicies(newFrameIndicies)
  }, [setFrameIndicies, timelineItems, settings])

  useEffect(() => {
    setTimelineItems((old) => {
      const newItems = cloneDeep(old)
      newItems[0].thumbnail = files.first?.thumbnailUrl
      newItems[0].visible = !!files.first?.thumbnailUrl
      newItems[0].percent = newItems[0].visible ? newItems[0].percent : null

      newItems[1].thumbnail = files.second?.thumbnailUrl
      newItems[1].visible = !!files.second?.thumbnailUrl
      newItems[1].percent = newItems[1].visible ? newItems[1].percent : null

      newItems[2].thumbnail = files.third?.thumbnailUrl
      newItems[2].visible = !!files.third?.thumbnailUrl
      newItems[2].percent = newItems[2].visible ? newItems[2].percent : null
      return newItems
    })
  }, [files])

  const iconStyle = cls('size-4', enableAfc ? 'text-icon-subdued' : 'text-icon-disable')
  const seperator = (
    <div className='flex items-center justify-center h-full px-0.5 md:px-4' aria-label='seperator'>
      <IconChevronRight className={iconStyle} />
    </div>
  )

  const uploadStyle = cls('w-0 flex-1 xs:w-40 max-w-40 h-[88px] rounded-xl overflow-hidden box-content')

  return (
    <div className={cls('flex w-full justify-center items-center', className)} aria-label='keyframe-conditioning'>
      <div className='flex flex-col justify-center w-full [@media(min-width:600px)]:w-max'>
        <div className='flex flex-col gap-1.5' aria-label='title'>
          <div className='flex items-center gap-2 text-body-md tracking-15' aria-label='keyframe conditioning switch'>
            <span className='leading-6'>Keyframe Conditioning</span>
            <div className='relative flex items-center'>
              <Switch
                className=''
                size='sm'
                checked={enableAfc}
                disabled={isModel2 && !showAfcModel2Entry}
                onCheckedChange={handleAfcChange}
              />
            </div>
            {/* {isModel2 && <span className='text-body-sm text-text-subdued'>Coming soon for Haiper 2.1</span>} */}
            {isModel2 && !showAfcModel2Entry && (
              <div className='text-body-sm text-text-subdued flex items-center'>
                <div>Only available in</div>
                <Button variant='link' className='px-1 h-6' onClick={openModelVersionPicker}>
                  <span className='text-text-interactive text-body-md font-medium'>Haiper 1.5</span>
                </Button>
              </div>
            )}
          </div>
          <ol
            className='flex flex-col m-0 text-body-md text-text-subdued tracking-15 bg-surface-subdued py-2 px-7 space-y-1 w-full rounded-md'
            aria-label='description'
          >
            <li className='m-0 text-body-sm'>Upload an image as a keyframe on the timeline.</li>
            <li className='m-0 text-body-sm'>
              If you upload multiple images, make sure objects in the images are consistent.
            </li>
          </ol>
        </div>
        <div
          className={cls('flex flex-col gap-2 items-center w-full mt-3', enableTimeline ? 'md:h-31' : 'md:h-24')}
          aria-label='uploads'
        >
          {enableTimeline && (
            <div className='w-full text-body-md tracking-15 text-text-subdued'>Upload Keyframe Image</div>
          )}
          {enableAfc || !isModel2 ? (
            <div className='flex md:h-24 items-center w-full'>
              <GSUpload
                fileType='image'
                theme={enableTimeline ? 'purple' : 'default'}
                className={cls(uploadStyle, '')}
                file={files.first}
                uploading={uploading.first}
                setUploading={(val) => setUploading((old) => ({ ...old, first: val }))}
                emptyText={enableTimeline ? undefined : isBelowMd ? 'First frame' : 'First frame image'}
                emptyIcon={enableTimeline ? undefined : IconFirstFrame}
                beforeUpload={beforeUpload}
                onChange={(file) => setFiles((old) => ({ ...old, first: file }))}
              />
              {seperator}
              <GSUpload
                fileType='image'
                theme={enableTimeline ? 'yellow' : 'default'}
                className={cls(uploadStyle, '')}
                file={files.second}
                uploading={uploading.second}
                setUploading={(val) => setUploading((old) => ({ ...old, second: val }))}
                emptyText={enableTimeline ? undefined : isBelowMd ? 'Middle frame' : 'Middle frame image'}
                emptyIcon={enableTimeline ? undefined : IconMiddleFrame}
                disabled={!enableAfc}
                beforeUpload={beforeUpload}
                onChange={(file) => setFiles((old) => ({ ...old, second: file }))}
              />
              {seperator}
              <GSUpload
                fileType='image'
                theme={enableTimeline ? 'green' : 'default'}
                className={cls(uploadStyle, '')}
                file={files.third}
                uploading={uploading.third}
                setUploading={(val) => setUploading((old) => ({ ...old, third: val }))}
                emptyText={enableTimeline ? undefined : isBelowMd ? 'Last frame' : 'Last frame image'}
                emptyIcon={enableTimeline ? undefined : IconLastFrame}
                disabled={!enableAfc}
                beforeUpload={beforeUpload}
                onChange={(file) => setFiles((old) => ({ ...old, third: file }))}
              />
            </div>
          ) : (
            <div className='flex md:h-24 items-center w-full justify-center'>
              <GSUpload
                fileType='image'
                theme='default'
                className={cls(uploadStyle, '')}
                file={files.first}
                uploading={uploading.first}
                setUploading={(val) => setUploading((old) => ({ ...old, first: val }))}
                beforeUpload={beforeUpload}
                onChange={(file) => setFiles((old) => ({ ...old, first: file }))}
              />
            </div>
          )}
        </div>
        {enableTimeline && (
          <div className='w-full flex flex-col gap-1 mt-4' aria-label='timeline'>
            <span className='text-text-subdued text-body-md tracking-15'>Place the frames on timeline</span>
            <AfcTimeline duration={settings.duration ?? null} items={timelineItems} onItemsChange={setTimelineItems} />
          </div>
        )}
        {extra ?? null}
      </div>
    </div>
  )
}
